import React, { useEffect } from 'react'
import * as s from "./slide2.module.css"

import { gsap } from "gsap/dist/gsap";
// import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import Sheme from '../assets/sheme.svg';
import { Waypoint } from 'react-waypoint';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

const blades = `#eq-blades`
const wave = `#eq-wave`

const ar0 = `#eq-ar0`
const ar1 = `#eq-ar1`
const ar2 = `#eq-ar2`
const ar3 = `#eq-ar3`

const electro = `#eq-electro`

const handleWaypointEnter = function(props){
  // start all animation
      gsap.killTweensOf([blades,wave, ar0, ar1, ar2, ar3, electro]);
      
      gsap.set(blades, {transformOrigin:"50.82% 67.28%", rotation:0})
      gsap.to(blades, 5, {rotation:360, repeat: -1, ease: "none"});

      gsap.set(wave, {x:0})
      gsap.to(wave, 1, {x:-38, repeat: -1, ease: "none"});


      gsap.set(ar0, {y:-35})
      gsap.to(ar0, 1, {y:60, repeat: -1, ease: "none"});

      gsap.set(ar1, {x:160})
      gsap.to(ar1, 1, {x:-120, repeat: -1, ease: "none"});

      gsap.set(ar2, {x:140})
      gsap.to(ar2, 1, {x:-80, repeat: -1, ease: "none"});

      gsap.set(ar3, {x:60, y:78})
      gsap.to(ar3, 1, {x:-45, y:-60, repeat: -1, ease: "none"});

      gsap.set(electro, {fill:`rgba(255,255,255,0)`})
      gsap.to(electro, 1, {fill:`rgba(255,255,255,1)`, repeat: -1, yoyo: true, ease: "none"});

}
const handleWaypointLeave = function(props){
  gsap.killTweensOf([blades,wave,ar0, ar1, ar2, ar3, electro]);
}
const isBrowser = typeof window !== "undefined"


const Slide2 = (props) => {

	const data = props.data;
	useEffect(() => {
	}, [])

	return (
    <Waypoint scrollableAncestor={isBrowser ? window : null} bottomOffset={"0%"} fireOnRapidScroll={false}
      onEnter={(props) => {handleWaypointEnter(props)}}
      onLeave={(props) => {handleWaypointLeave(props)}}
    >
      <section className={s.slide2} id={`eq-st2`}>
        
        <div className={s.window}>
  				<div className={s.h0} dangerouslySetInnerHTML={{__html: data.id + '. ' + data.h0 }} />
  				<div className={s.line} />
  				{
  					data.txt.map((d,i)=>(
  	           <ScrollAnimation key={`txt`+i} animateIn="fadeIn" animateOnce={true}>
               <div
  	              className={s.txt}
  	              dangerouslySetInnerHTML={{__html: data.txt[i] }}
  	            />
                </ScrollAnimation>
  					))
  				}
  			</div>

  			<div className={s.sheme}>
  				<Sheme />
  			</div>

  		</section>
    </Waypoint>
)}

export default Slide2
