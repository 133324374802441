// extracted by mini-css-extract-plugin
export const window = "job-module--window--2WJVn";
export const gbbox = "job-module--gbbox--1YO9q";
export const title = "job-module--title--2ohvx";
export const h0 = "job-module--h0--3ESTD";
export const line = "job-module--line--1t0YJ";
export const desc = "job-module--desc--DHZOW";
export const descm = "job-module--descm--3Tflg";
export const points = "job-module--points--3FSh-";
export const point = "job-module--point--1ONIc";
export const pointc1 = "job-module--pointc1--1iJfi";
export const pointc2 = "job-module--pointc2--2w6O7";
export const pointc0 = "job-module--pointc0--1lMU6";
export const tippy = "job-module--tippy--3dB_A";
export const tippyh0 = "job-module--tippyh0--haK74";
export const tippydesc = "job-module--tippydesc--13DlY";
export const values = "job-module--values--pd0vh";
export const arrow = "job-module--arrow--1XrEh";
export const year = "job-module--year--Yuebc";
export const yearval = "job-module--yearval--3id7i";
export const percents = "job-module--percents--2QCKi";
export const circle = "job-module--circle--3T4DX";
export const txt = "job-module--txt--10pfn";
export const sector = "job-module--sector--3h_Eq";
export const years = "job-module--years--IANHt";