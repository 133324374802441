import React from 'react'
import * as s from "./dgraph.module.css"
import Dgraphsvg from '../assets/dgraph.svg';

const Dgraph = (props) => {
	return (
		<section className={s.dgraphbox}>
			<Dgraphsvg />
		</section>
)}

export default Dgraph