import React, { useEffect } from 'react'
import * as s from "./slide7.module.css"
import ScrollAnimation from 'react-animate-on-scroll';
import Job from "./job"

const Slide7 = (props) => {

	const data = props.data;
	useEffect(() => {
	}, [])

	return (
	<>
		<section className={s.slide7} id={`eq-slide7`}>
			
			<div className={s.window}>
				<div className={s.h0} dangerouslySetInnerHTML={{__html: data.id + '. ' + data.h0 }} />
				<div className={s.line} />
				{
					data.txt.map((d,i)=>(
			           <ScrollAnimation key={`txt`+i} animateIn="fadeIn" animateOnce={true}>
			           <div
			              key={`txt`+i}
			              className={s.txt}
			              dangerouslySetInnerHTML={{__html: data.txt[i] }}
			            />
			            </ScrollAnimation>
					))
				}
			</div>

			{
				<div className={s.jobbox}>
				{
					props.mobile && <Job data={data.job} />
				}
				</div>
			}

		</section>
	</>

)}

export default Slide7
