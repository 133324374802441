import React from 'react'
import * as s from "./folder.module.css"

const Folder = (props) => {

	const data = props.data;

	return (
		<section className={s.folder}>
			<div className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
			<div className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
		</section>
)}

export default Folder