// extracted by mini-css-extract-plugin
export const slide3 = "slide3-module--slide3--3d9fJ";
export const window = "slide3-module--window--343Te";
export const h0 = "slide3-module--h0--Mu5XL";
export const line = "slide3-module--line--3RZbB";
export const txt = "slide3-module--txt--UL-EX";
export const capacitybox = "slide3-module--capacitybox--37R3e";
export const quote = "slide3-module--quote--1iW9S";
export const qq = "slide3-module--qq--2x4bY";
export const quotetext = "slide3-module--quotetext--3C6Ec";
export const quoteline = "slide3-module--quoteline--Pm63j";
export const quotename = "slide3-module--quotename--31O1B";
export const quotedesc = "slide3-module--quotedesc--5yjI2";
export const slide3f = "slide3-module--slide3f--2JdA7";
export const facth0 = "slide3-module--facth0--35rAV";
export const factstxt = "slide3-module--factstxt--10inC";
export const windowf = "slide3-module--windowf--3bksp";