// extracted by mini-css-extract-plugin
export const window = "slider-module--window--18_jB";
export const h0 = "slider-module--h0--2KBrt";
export const line = "slider-module--line--VfIhR";
export const desc = "slider-module--desc--3xaEW";
export const lampblock = "slider-module--lampblock--3vKZv";
export const roundblock = "slider-module--roundblock--3eguR";
export const name = "slider-module--name--3G5e1";
export const val = "slider-module--val--2CUzK";
export const slidercontainer = "slider-module--slidercontainer--hfsyS";
export const slider = "slider-module--slider--3mAgp";
export const thumb = "slider-module--thumb--1hTw8";
export const gcircle = "slider-module--gcircle--3kvDU";
export const circle = "slider-module--circle--1LPqp";
export const track = "slider-module--track--2OuOT";
export const mark = "slider-module--mark--ZLswa";
export const lamp = "slider-module--lamp--3c05P";
export const light = "slider-module--light--v0WKi";
export const light2 = "slider-module--light2--2fHOJ";
export const val1 = "slider-module--val1--3bDHA";
export const val2 = "slider-module--val2--uVOS9";
export const years = "slider-module--years--2NOeI";
export const year = "slider-module--year--8s330";