import React, { useEffect } from 'react'
import * as s from "./main.module.css"

import Folder from "./folder"
import Slide1 from "./slide1"
import Slide2 from "./slide2"
import Slide3 from "./slide3"
import Slide4 from "./slide4"
import Slide5 from "./slide5"
import Slide6 from "./slide6"
import Slide7 from "./slide7"
import Slide8 from "./slide8"
import Footer from "./footer"


import Clusters from "./clusters"
import Capacity from "./capacity"
import Dgraph from "./dgraph"
import Graph from "./graph"
import Job from "./job"

import Peterhead from '../assets/peterhead.svg';
import Dogger from "../assets/dogger2.svg"
import Winds from "../assets/winds.svg"
import Mouse from "../assets/mouse.svg"

import { scrolltr } from '../utils/index.js'
import useWindowSize from "../utils/useWindowSize";

import { withpath } from '../utils/index.js'

const Main = (props) => {

	const data = props.data;
	const { mobile } = useWindowSize();

	useEffect(() => {
		console.log(`mobile is ` + mobile )
		scrolltr(mobile);
	}, [])

	return (
		<>
			<section className={s.bg}>

				<div className={s.map}>
					<div className={s.mapcontainter} id={`eq-mapcontainer`}>
	            		
	            		<img 
	                      className={s.mapimg}
	                      src={withpath(`/img/map13.svg`)}
	                      alt={`map`}
	                    />

						<div className={s.dogger} id={`eq-dogger`}>
							<Dogger />
						</div>

						<div className={s.winds} id={`eq-winds17`}>
							<Winds />
						</div>

					</div>
				</div>

		

					<div className={s.peterhead} id={`eq-peterhead`} ><Peterhead /></div>

					<div className={s.dgraph} id={`eq-dgraph`}>
						<Dgraph />
					</div>

					<div className={s.bg3} id={`eq-bg3`} >
						<video src={withpath(`/video/loop.mp4`)} autoPlay loop playsInline muted/>
					</div>
					<div className={s.bg4} id={`eq-bg4`} style={{backgroundImage: `url(${withpath(`/img/bg4.jpg`)})` }}>
						<div className={s.gradient} />
					</div>

					<div className={s.h0box} id={`eq-h0box`}>
						<div className={s.h0s4} dangerouslySetInnerHTML={{__html: data.sections[4].id + '. ' + data.sections[4].h0 }} />
						<div className={s.lines4} />
						<div className={s.desc4} id={`eq-h0desc`} dangerouslySetInnerHTML={{__html: data.sections[4].desc }} />
					</div>

					<div className={s.bg8} id={`eq-bg8`} style={{backgroundImage: `url(${withpath(`/img/bg8.jpg`)})` }}/>
					<div className={s.bg9} id={`eq-bg9`} />

					<div className={s.graphbox} id={`eq-grapho`}>
						<div className={s.graphbox1300}>
							<div className={s.graphboxhalf}>
								<Graph data={data.sections[6].graph} idd={``}/>
							</div>
						</div>
					</div>

					<div className={s.jobbox} id={`eq-job`}>
						<Job data={data.sections[7].job} />
					</div>

				<Clusters data={data.sections[1].clusters} mobile={mobile} />

					<Capacity />

				<div className={s.mouse} id={`eq-mouse`}>
					<Mouse />
					<div className={s.mousetxt} id={`eq-mousetxt`} dangerouslySetInnerHTML={{__html: `Scroll down to explore` }} />
					<div className={s.mousetxtm} id={`eq-mousetxtm`} dangerouslySetInnerHTML={{__html: `Swipe down to explore` }} />
				</div>

			</section>

			<section className={s.sections}>
				<Folder data={data.sections[0]} />
				<Slide1 data={data.sections[1]} />
				<Slide2 data={data.sections[2]} />
				<Slide3 data={data.sections[3]} mobile={mobile} />
				<Slide4 data={data.sections[4]} mobile={mobile} />
				<Slide5 data={data.sections[5]} />
				<Slide6 data={data.sections[6]} mobile={mobile} />
				<Slide7 data={data.sections[7]} mobile={mobile} />
				<Slide8 data={data.sections[8]} />
				<Footer data={data.sections[9]} />

			</section>
		</>
)}

export default Main
