import React, { useEffect } from 'react'
import * as s from "./slide8.module.css"
import { withpath } from '../utils/index.js'
const Slide8 = (props) => {

	const data = props.data;
	useEffect(() => {
	}, [])

	return (
	<>
		<section className={s.slide8f} id={`eq-slide8f`}>
			<div className={s.windowf}>
				<div className={s.facth0} dangerouslySetInnerHTML={{__html: data.facts.h0 }} />
				<div className={s.line} />
				{
					data.facts.txt.map((d,i)=>(
			           <div
			              key={`txt`+i}
			              data-id={i}
			              className={s.factstxt}
			              dangerouslySetInnerHTML={{__html: data.facts.txt[i] }}
			            />
					))
				}
			</div>
		</section>
		<section className={s.slide8b} id={`eq-slide8b`} style={{backgroundImage: `url(${withpath(`/img/bg8.jpg`)})` }}>
			<div className={s.windowb}>
	           <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} />
	           <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} />
			</div>
		</section>
	</>

)}

export default Slide8
