// extracted by mini-css-extract-plugin
export const h0box = "slide4-module--h0box--MYned";
export const h0s4 = "slide4-module--h0s4--2m_Ea";
export const lines4 = "slide4-module--lines4--1GmD8";
export const desc4 = "slide4-module--desc4--3VGcd";
export const slide4a = "slide4-module--slide4a--H38rC";
export const windowa = "slide4-module--windowa--1aqFB";
export const txt = "slide4-module--txt--7zhti";
export const slide4b = "slide4-module--slide4b--2ZmXA";
export const windowb = "slide4-module--windowb--Ww0z0";
export const slide4c = "slide4-module--slide4c--37LG8";
export const peterhead = "slide4-module--peterhead--2DdEI";
export const windowc = "slide4-module--windowc--EiUg8";
export const slide4d = "slide4-module--slide4d--3poOQ";
export const dgraph = "slide4-module--dgraph--1J668";
export const windowd = "slide4-module--windowd--1-yjv";