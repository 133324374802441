import React, { useEffect } from 'react'
import * as s from "./slide5.module.css"
import Slider from "./slider"
import ScrollAnimation from 'react-animate-on-scroll';

const Slide5 = (props) => {

	const data = props.data;
	useEffect(() => {

	}, [])


	return (
	<>
		<section className={s.slide5}>
			<div className={s.box}>
				<div className={s.window}>
					<div className={s.h0} dangerouslySetInnerHTML={{__html: data.id + '. ' + data.h0 }} />
					<div className={s.line} />
					{
						data.txt.map((d,i)=>(
				           <ScrollAnimation key={`txt`+i} animateIn="fadeIn" animateOnce={true}>
				           <div
				              key={`txt`+i}
				              className={s.txt}
				              dangerouslySetInnerHTML={{__html: data.txt[i] }}
				            />
				            </ScrollAnimation>
						))
					}
				</div>

				<Slider data={data.graph} />
			</div>
		</section>
	</>

)}

export default Slide5
