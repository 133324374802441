import React from "react";

export default function useWindowSize() {
  const isBrowser = typeof window !== "undefined";
  const [windowSize, setWindowSize] = React.useState({ mobile: isBrowser ? (window.innerWidth <= 768) : false });

  function changeWindowSize() {
    setWindowSize({ mobile: window.innerWidth <= 768 });
  }

  React.useEffect(() => {
    window.addEventListener("resize", changeWindowSize);
    changeWindowSize();
  }, []);

  return windowSize;
}
