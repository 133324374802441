import React, { useEffect } from 'react'
import * as s from "./slide4.module.css"

import Peterhead from '../assets/peterhead.svg';
import Dgraph from "./dgraph"
import { withpath } from '../utils/index.js'
const Slide4 = (props) => {

	const data = props.data;
	useEffect(() => {
	}, [])

	return (
	<>
		<section className={s.slide4a} id={'eq-st4a'} style={{backgroundImage: `url(${withpath(`/img/bg4.jpg`)})` }}>
			
			{
				<div className={s.h0box}>
					<div className={s.h0s4} dangerouslySetInnerHTML={{__html: data.id + '. ' + data.h0 }} />
					<div className={s.lines4} />
					<div className={s.desc4} id={`eq-h0desc`} dangerouslySetInnerHTML={{__html: data.desc }} />
				</div>
			}

			<div className={s.windowa}>
				<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} />
			</div>

		</section>



		<section className={s.slide4d} id={'eq-st4d'}>

			{
				<div className={s.dgraph}>
					{
						props.mobile && <Dgraph />
					}
				</div>
			}

			<div className={s.windowd}>
				<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[4] }} />
			</div>

		</section>


		<section className={s.slide4b} id={'eq-st4b'}>
			<div className={s.windowb}>
				<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} />
				<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[2] }} />
			</div>
		</section>


		<section className={s.slide4c} id={'eq-st4c'}>

			{
				<div className={s.peterhead}>
					{
						props.mobile && <Peterhead />
					}
				</div>
			}

			<div className={s.windowc}>
				<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[3] }} />
			</div>
			
		</section>


	</>

)}

export default Slide4
