import React, { useEffect } from 'react'
import * as s from "./job.module.css"
import Gb from '../assets/gb2.svg';

const Job = (props) => {

	useEffect(() => {
	},[])

	return (
		<section className={s.window}>
			<div className={s.gbbox}>
				<Gb />
			</div>
		</section>
)}

export default Job