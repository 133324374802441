import React, { useEffect } from 'react'
import * as s from "./slide3.module.css"
import { gsap } from "gsap/dist/gsap";
import { Waypoint } from 'react-waypoint';
import ScrollAnimation from 'react-animate-on-scroll';
import Capacity from "./capacity"
import { withpath } from '../utils/index.js'
const handleWaypointEnter1 = function(props){
	gsap.to("#eq-qq", {scale: 1, opacity: 1, ease:`power2.out`, duration: 0.5});
}

const handleWaypointEnter2 = function(props){
	gsap.to("#eq-quoteline", {scaleX: 1, opacity: 1, ease:`power2.out`, duration: 0.5});
}

const isBrowser = typeof window !== "undefined"

const Slide3 = (props) => {

	const data = props.data;
	useEffect(() => {
	}, [])


	return (
	<>
		<section className={s.slide3} id={`eq-st3`}>
			<div className={s.window}>
				<div className={s.h0} dangerouslySetInnerHTML={{__html: data.id + '. ' + data.h0 }} />
				<div className={s.line} />
				{
					data.txt.map((d,i)=>(
			           <ScrollAnimation key={`txt`+i} animateIn="fadeIn" animateOnce={true}>
			           <div
			           
			              className={s.txt}
			              dangerouslySetInnerHTML={{__html: data.txt[i] }}
			            />
			            </ScrollAnimation>
					))
				}
			</div>

			{
				<div className={s.capacitybox}>
				{
					props.mobile && <Capacity />
				}
				</div>
			}

		</section>

		<section className={s.quote}  id={`eq-quotes`}>
			<Waypoint scrollableAncestor={isBrowser ? window : null} bottomOffset={"50px"} fireOnRapidScroll={false}
			  onEnter={(props) => {handleWaypointEnter1(props)}}
			>
				<div className={s.qq} id={`eq-qq`} dangerouslySetInnerHTML={{__html: "“" }} />
			</Waypoint>

			<ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={50}>
				<div className={s.quotetext} dangerouslySetInnerHTML={{__html: data.quote.txt }} />
			</ScrollAnimation>

			<Waypoint scrollableAncestor={isBrowser ? window : null} bottomOffset={"50px"} fireOnRapidScroll={false}
			  onEnter={(props) => {handleWaypointEnter2(props)}}
			>
				<div id={`eq-quoteline`} className={s.quoteline} />
			</Waypoint>

			<ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={50}>
				<div className={s.quotename} dangerouslySetInnerHTML={{__html: data.quote.name }}/>
			</ScrollAnimation>
			<ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={50}>
				<div className={s.quotedesc} dangerouslySetInnerHTML={{__html: data.quote.desc }}/>
			</ScrollAnimation>
		</section>

		<section className={s.slide3f} id={`eq-st3f`}>
			<video src={withpath(`/video/loop.mp4`)} autoPlay loop playsInline muted/>
			<div className={s.windowf}>
				<div className={s.facth0} dangerouslySetInnerHTML={{__html: data.facts.h0 }} />
				<div className={s.line} />
				{
					data.facts.txt.map((d,i)=>(
			           <div
			              key={`txt`+i}
			              data-id={i}
			              className={s.factstxt}
			              dangerouslySetInnerHTML={{__html: data.facts.txt[i] }}
			            />
					))
				}
			</div>
		</section>
	</>

)}

export default Slide3
