import React from 'react'
import * as s from "./footer.module.css"

const Footer = (props) => {

	const data = props.data;

	return (
		<section className={s.footer}>
			<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt }} />
			<div className={s.line} />
			<ol className={s.ol}>
			{
				data.links.map((d,i)=>(
					<li key={i} className={s.li}>
						{ (d.substring(0,4)===`http`) && <a aria-label={d} className={s.a} href={d} target={`_blank`} dangerouslySetInnerHTML={{__html: d }} /> }
						{ (d.substring(0,4)!==`http`) && <span dangerouslySetInnerHTML={{__html: d }} /> }
					</li>
				))
			}
			</ol>
		</section>
)}

export default Footer