import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

export const PATH = `https://offshorewindeu.alphagridinfographics.com`
export function withpath(name){
	return PATH + name;
}

export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getST(trigger, start, end, scrub = 0, markers = false){
	return {
		trigger: trigger,
		start: start,
		end: end,
		scrub: scrub,
		markers: markers,
	}
}


let maptl1 = gsap.timeline();
let maptl2 = gsap.timeline();
let maptl3 = gsap.timeline();

export function scrolltr(mobile){
	gsap.registerPlugin(ScrollTrigger);
	gsap.core.globals("ScrollTrigger", ScrollTrigger);
	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
	});
	
	const MP = `#eq-mapcontainer`

	// mp
	{
		maptl1.kill(true);
		maptl2.kill(true);
		maptl3.kill(true);
		gsap.set(MP, {clearProps: true});
		maptl1 = gsap.timeline({scrollTrigger: getST(`#eq-st0`,`0% 100%`,`0% 0%`)});
		maptl2 = gsap.timeline({scrollTrigger: getST(`#eq-st2`,`0% 100%`,`50% 100%`)});
		maptl3 = gsap.timeline({scrollTrigger: getST(`#eq-slide8f`,`0% 100%`,`0% 0%`)});

		if(mobile){
			maptl1.set(MP, {scale: 1, x:0, y: 0});
			maptl1.to(MP, {scale: 0.5, x: 0, y: 100});

			maptl2.set(MP, {autoAlpha: 1, scale: 0.5, x: 0, y: 100});
			maptl2.to(MP, {autoAlpha: 0, scale: 0.5, x: 0, y: 100});

			maptl3.set(MP, {autoAlpha: 0, scale: 2, x: 318, y: 615});
			maptl3.to(MP, {autoAlpha: 1, scale: 2, x: 318, y: 615});

		}else{
			maptl1.set(MP, {scale: 1, x:0, y: 0});
			maptl1.to(MP, {scale: 1.4, x: -150, y: 100});

			maptl2.set(MP, {autoAlpha: 1, scale: 1.4, x: -150, y: 100});
			maptl2.to(MP, {autoAlpha: 0, scale: 1.4, x: -150, y: 100});
			
			maptl3.set(MP, {autoAlpha: 0, x: 617, y: 975, scale: 2.9});
			maptl3.to(MP, {autoAlpha: 1, x: 617, y: 975, scale: 2.9});
		}

	}



	// slide0
	{
		const EL3 = `#eq-winds17`

		gsap.timeline({scrollTrigger: getST(`#eq-st0`,`0% 0%`,`50% 50%`)})
		.set(EL3, {autoAlpha: 0})
		.to(EL3, {autoAlpha: 1})

	}


	// slide 1
	{

		const EL00 = `#eq-mouse`
		const ST00 = {
		  trigger: `#eq-st0`,
		  start: "top bottom",
		  end: "top top",
		  scrub: 0,
		  markers: false,
		  onLeave: ({progress, direction, isActive}) => {
		    gsap.killTweensOf(`#eq-mouse-arrow`);
		  },
		  onEnterBack: ({progress, direction, isActive}) => {
		    gsap.killTweensOf(`#eq-mouse-arrow`);
		    gsap.set(`#eq-mouse-arrow`, {y:-3})
		    gsap.to("#eq-mouse-arrow", {y: 3, repeat:-1, yoyo: true, ease:`power2.out`, duration: 0.75});
		  },
		}

		let tl00 = gsap.timeline({scrollTrigger: ST00});
		tl00.set(EL00, {autoAlpha: 1});
		tl00.to(EL00, {autoAlpha: 0});






		const ELTH = `#eq-title`
		const ELP0 = `#eq-point0`
		const ELP1 = `#eq-point1`
		const ELP2 = `#eq-point2`
		const ELP3 = `#eq-point3`
		const ELP4 = `#eq-point4`
		
		const STH = {
			trigger: `#eq-st1`,
			start: "0% 100%",
			end: "30% 100%",
			scrub: 0,
			markers: false
		}
		const STP0 = {
			trigger: `#eq-st1`,
			start: "10% 100%",
			end: "40% 100%",
			scrub: 0,
			markers: false
		}
		const STP1 = {
			trigger: `#eq-st1`,
			start: "20% 100%",
			end: "50% 100%",
			scrub: 0,
			markers: false
		}
		const STP2 = {
			trigger: `#eq-st1`,
			start: "30% 100%",
			end: "60% 100%",
			scrub: 0,
			markers: false
		}
		const STP3 = {
			trigger: `#eq-st1`,
			start: "40% 100%",
			end: "70% 100%",
			scrub: 0,
			markers: false
		}
		const STP4 = {
			trigger: `#eq-st1`,
			start: "50% 100%",
			end: "80% 100%",
			scrub: 0,
			markers: false
		}



		let tlh = gsap.timeline({scrollTrigger: STH});
		tlh.set(ELTH, {autoAlpha: 0});
		tlh.to(ELTH, {autoAlpha: 1});

		let tl0 = gsap.timeline({scrollTrigger: STP0});
		tl0.set(ELP0, {autoAlpha: 0});
		tl0.to(ELP0, {autoAlpha: 1});

		let tl1 = gsap.timeline({scrollTrigger: STP1});
		tl1.set(ELP1, {autoAlpha: 0});
		tl1.to(ELP1, {autoAlpha: 1});

		let tl2 = gsap.timeline({scrollTrigger: STP2});
		tl2.set(ELP2, {autoAlpha: 0});
		tl2.to(ELP2, {autoAlpha: 1});

		let tl3 = gsap.timeline({scrollTrigger: STP3});
		tl3.set(ELP3, {autoAlpha: 0});
		tl3.to(ELP3, {autoAlpha: 1});

		let tl4 = gsap.timeline({scrollTrigger: STP4});
		tl4.set(ELP4, {autoAlpha: 0});
		tl4.to(ELP4, {autoAlpha: 1});


	}

	//slide 2
	{

		const EL2 = `#eq-gbbox`
		const ST2 = {
		  trigger: `#eq-st2`,
		  start: "top bottom",
		  end: "top top",
		  scrub: 0,
		  markers: false
		}





      let tl2 = gsap.timeline({scrollTrigger: ST2});
      tl2.set(EL2, {autoAlpha: 1});
      tl2.to(EL2, {autoAlpha: 0});


		const EL3 = `#eq-winds17`

		gsap.timeline({scrollTrigger: getST(`#eq-st2`,`0% 100%`,`0% 0%`)})
		.set(EL3, {autoAlpha: 1})
		.to(EL3, {autoAlpha: 0})



	}

	//slide3
	{
		const EL1 = `#eq-capacity`
		const ST1 = {
		  trigger: `#eq-st3`,
		  start: "top bottom",
		  end: "top top",
		  scrub: 0,
		  markers: false
		}

		const EL2 = `#eq-capacity`
		const ST2 = {
		  trigger: `#eq-quotes`,
		  start: "top top",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}

		const EL3 = `#eq-bg3`
		const ST3 = {
		  trigger: `#eq-quotes`,
		  start: "top top",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}

      let tl1 = gsap.timeline({scrollTrigger: ST1});
      tl1.set(EL1, {autoAlpha: 0});
      tl1.to(EL1, {autoAlpha: 1});

      let tl2 = gsap.timeline({scrollTrigger: ST2});
      tl2.set(EL2, {autoAlpha: 1});
      tl2.to(EL2, {autoAlpha: 0});

      let tl3 = gsap.timeline({scrollTrigger: ST3});
      tl3.set(EL3, {autoAlpha: 0});
      tl3.to(EL3, {autoAlpha: mobile?0:1});


	}

	// slide4
	{

		const EL1 = `#eq-bg4`
		const ST1 = {
		  trigger: `#eq-st4a`,
		  start: "top bottom",
		  end: "top top",
		  scrub: 0,
		  markers: false
		}

		const EL2 = `#eq-bg4`
		const ST2 = {
		  trigger: `#eq-st4a`,
		  start: "bottom bottom",
		  end: "bottom top",
		  scrub: 0,
		  markers: false
		}

		const EL3 = `#eq-bg3`
		const ST3 = {
		  trigger: `#eq-st4a`,
		  start: "bottom bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}

		const EL4 = `#eq-peterhead`
		const ST4 = {
		  trigger: `#eq-st4d`,
		  start: "bottom bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}

		const EL4_2 = `#eq-pthd`
		const ST4_2 = {
		  trigger: `#eq-st4b`,
		  start: "center bottom",
		  end: "center top",
		  scrub: 0,
		  markers: false
		}

		const EL5 = `#eq-peterhead`
		const ST5 = {
		  trigger: `#eq-st4c`,
		  start: "bottom bottom",
		  end: "bottom center",
		  scrub: 0,
		  markers: false
		}

		const EL6 = `#eq-h0box`
		const ST6 = {
		  trigger: `#eq-st4a`,
		  start: "top bottom",
		  end: "top top",
		  scrub: 0,
		  markers: false
		}

		const EL7 = `#eq-h0desc`
		const ST7 = {
		  trigger: `#eq-st4d`,
		  start: "bottom bottom",
		  end: "bottom top",
		  scrub: 0,
		  markers: false
		}

		const EL8 = `#eq-h0desc`
		const ST8 = {
		  trigger: `#eq-st4c`,
		  start: "bottom bottom",
		  end: "bottom center",
		  scrub: 0,
		  markers: false
		}

		const EL9 = `#eq-dgraph`
		const ST9 = {
		  trigger: `#eq-st4a`,
		  start: "bottom center",
		  end: "bottom top",
		  scrub: 0,
		  markers: false
		}

		const EL9_2 = `.eq-cone-rect`
		const ST9_2 = {
		  trigger: `#eq-st4a`,
		  start: "bottom center",
		  end: "bottom top",
		  scrub: 0,
		  markers: false
		}

		const EL9_3_0 = `.eq-cone-all[data-id="0"]`
		const EL9_3_1 = `.eq-cone-all[data-id="1"]`
		const EL9_3_2 = `.eq-cone-all[data-id="2"]`
		const EL9_3_3 = `.eq-cone-all[data-id="3"]`
		const EL9_3_4 = `.eq-cone-all[data-id="4"]`
		const EL9_3_5 = `.eq-cone-all[data-id="5"]`
		const ST9_3 = {
		  trigger: `#eq-st4a`,
		  start: "bottom center",
		  end: "bottom top",
		  scrub: 0,
		  markers: false
		}

		const EL10 = `#eq-dgraph`
		const ST10 = {
		  trigger: `#eq-st4d`,
		  start: "bottom bottom",
		  end: "bottom top",
		  scrub: 0,
		  markers: false
		}

		const EL11 = `#eq-h0box`
		const ST11 = {
		  trigger: `#eq-st4c`,
		  start: "bottom bottom",
		  end: "bottom top",
		  scrub: 0,
		  markers: false
		}







		let tl1 = gsap.timeline({scrollTrigger: ST1});
		tl1.set(EL1, {autoAlpha: 0});
		tl1.to(EL1, {autoAlpha: 1});

		let tl2 = gsap.timeline({scrollTrigger: ST2});
		tl2.set(EL2, {autoAlpha: 1});
		tl2.to(EL2, {autoAlpha: 0});

		let tl3 = gsap.timeline({scrollTrigger: ST3});
		tl3.set(EL3, {autoAlpha: mobile?0:1});
		tl3.to(EL3, {autoAlpha: 0});

		let tl4 = gsap.timeline({scrollTrigger: ST4});
		tl4.set(EL4, {autoAlpha: 0});
		tl4.to(EL4, {autoAlpha: 1});

		let tl4_2 = gsap.timeline({scrollTrigger: ST4_2});
		tl4_2.set(EL4_2, {autoAlpha: 0, scale: 0.75, transformOrigin:'center bottom'});
		tl4_2.to(EL4_2, {autoAlpha: 1, scale: 1});

		let tl5 = gsap.timeline({scrollTrigger: ST5});
		tl5.set(EL5, {autoAlpha: 1});
		tl5.to(EL5, {autoAlpha: 0});

		let tl6 = gsap.timeline({scrollTrigger: ST6});
		tl6.set(EL6, {autoAlpha: 0});
		tl6.to(EL6, {autoAlpha: 1});

		let tl7 = gsap.timeline({scrollTrigger: ST7});
		tl7.set(EL7, {autoAlpha: 0});
		tl7.to(EL7, {autoAlpha: 1});

		let tl8 = gsap.timeline({scrollTrigger: ST8});
		tl8.set(EL8, {autoAlpha: 1});
		tl8.to(EL8, {autoAlpha: 0});

		let tl9 = gsap.timeline({scrollTrigger: ST9});
		tl9.set(EL9, {autoAlpha: 0});
		tl9.to(EL9, {autoAlpha: 1});

			gsap.set(EL9_2, {scaleY: 0, transformOrigin: `center bottom`});
			let tl9_2 = gsap.timeline({scrollTrigger: ST9_2});
			tl9_2.set(EL9_2, {scaleY: 0, transformOrigin: `center bottom`});
			tl9_2.to(EL9_2, {scaleY: 1});

			gsap.set(EL9_3_0, {y: 349, transformOrigin: `center bottom`});
			let tl9_3_0 = gsap.timeline({scrollTrigger: ST9_3});
			tl9_3_0.set(EL9_3_0, {y: 349, transformOrigin: `center bottom`});
			tl9_3_0.to(EL9_3_0, {y: 0});

			gsap.set(EL9_3_1, {y: 241, transformOrigin: `center bottom`});
			let tl9_3_1 = gsap.timeline({scrollTrigger: ST9_3});
			tl9_3_1.set(EL9_3_1, {y: 241, transformOrigin: `center bottom`});
			tl9_3_1.to(EL9_3_1, {y: 0});

			gsap.set(EL9_3_2, {y: 184, transformOrigin: `center bottom`});
			let tl9_3_2 = gsap.timeline({scrollTrigger: ST9_3});
			tl9_3_2.set(EL9_3_2, {y: 184, transformOrigin: `center bottom`});
			tl9_3_2.to(EL9_3_2, {y: 0});

			gsap.set(EL9_3_3, {y: 124, transformOrigin: `center bottom`});
			let tl9_3_3 = gsap.timeline({scrollTrigger: ST9_3});
			tl9_3_3.set(EL9_3_3, {y: 124, transformOrigin: `center bottom`});
			tl9_3_3.to(EL9_3_3, {y: 0});

			gsap.set(EL9_3_4, {y: 94, transformOrigin: `center bottom`});
			let tl9_3_4 = gsap.timeline({scrollTrigger: ST9_3});
			tl9_3_4.set(EL9_3_4, {y: 94, transformOrigin: `center bottom`});
			tl9_3_4.to(EL9_3_4, {y: 0});

			gsap.set(EL9_3_5, {y: 54, transformOrigin: `center bottom`});
			let tl9_3_5 = gsap.timeline({scrollTrigger: ST9_3});
			tl9_3_5.set(EL9_3_5, {y: 54, transformOrigin: `center bottom`});
			tl9_3_5.to(EL9_3_5, {y: 0});


		let tl10 = gsap.timeline({scrollTrigger: ST10});
		tl10.set(EL10, {autoAlpha: 1});
		tl10.to(EL10, {autoAlpha: 0});

		let tl11 = gsap.timeline({scrollTrigger: ST11});
		tl11.set(EL11, {autoAlpha: 1});
		tl11.to(EL11, {autoAlpha: 0});


	}

	//slide5


	//slide6
	{
		const EL1 = `#eq-grapho`
		const ST1 = {
		  trigger: `#eq-slide6`,
		  start: "top bottom",
		  end: "top top",
		  scrub: 0,
		  markers: false
		}

		const EL1_1 = `#eq-grapho #eqgclip`
		const ST1_1 = {
		  trigger: `#eq-slide6`,
		  start: "top center",
		  end: "top top",
		  scrub: 0,
		  markers: false
		}

		const EL2 = `#eq-grapho`
		const ST2 = {
		  trigger: `#eq-slide6`,
		  start: "bottom bottom",
		  end: "bottom center",
		  scrub: 0,
		  markers: false
		}

		const EL3 = `#eq-grapho #eq-graphpoints`
		const ST3 = {
		  trigger: `#eq-slide6`,
		  start: "top top",
		  end: "100px top",
		  scrub: 0,
		  markers: false
		}

		let tl1 = gsap.timeline({scrollTrigger: ST1});
		tl1.set(EL1, {autoAlpha: 0});
		tl1.to(EL1, {autoAlpha: 1});

		gsap.set(EL1_1, {scaleX: 0});
		let tl1_1 = gsap.timeline({scrollTrigger: ST1_1});
		tl1_1.set(EL1_1, {scaleX: 0});
		tl1_1.to(EL1_1, {scaleX: 1});

		let tl2 = gsap.timeline({scrollTrigger: ST2});
		tl2.set(EL2, {autoAlpha: 1});
		tl2.to(EL2, {autoAlpha: 0});

		gsap.set(EL3, {autoAlpha: 0});
		let tl3 = gsap.timeline({scrollTrigger: ST3});
		tl3.set(EL3, {autoAlpha: 0});
		tl3.to(EL3, {autoAlpha: 1});

	}

	// slide7
	{
		const EL1 = `#eq-job`
		const ST1 = {
		  trigger: `#eq-slide7`,
		  start: "top center",
		  end: "top top",
		  scrub: 0,
		  markers: false
		}

		const EL2 = `#eq-job`
		const ST2 = {
		  trigger: `#eq-slide7`,
		  start: "bottom bottom",
		  end: "bottom center",
		  scrub: 0,
		  markers: false
		}

      let tl1 = gsap.timeline({scrollTrigger: ST1});
      tl1.set(EL1, {autoAlpha: 0});
      tl1.to(EL1, {autoAlpha: mobile?0:1 });

      let tl2 = gsap.timeline({scrollTrigger: ST2});
      tl2.set(EL2, {autoAlpha: mobile?0:1 });
      tl2.to(EL2, {autoAlpha: 0});

	}

	//slide8
	{



		const EL2 = `#eq-dogger`
		const ST2 = {
		  trigger: `#eq-slide8f`,
		  start: "top bottom",
		  end: "top top",
		  scrub: 0,
		  markers: false
		}

		const EL3 = `#eq-bg8`
		const ST3 = {
		  trigger: `#eq-slide8f`,
		  start: "bottom bottom",
		  end: "bottom top",
		  scrub: 0,
		  markers: false
		}

		const EL4 = `#eq-bg9`
		const ST4 = {
		  trigger: `#eq-slide8b`,
		  start: "bottom bottom",
		  end: "bottom top",
		  scrub: 0,
		  markers: false
		}

      let tl2 = gsap.timeline({scrollTrigger: ST2});
      tl2.set(EL2, {autoAlpha: 0});
      tl2.to(EL2, {autoAlpha: 1});

      let tl3 = gsap.timeline({scrollTrigger: ST3});
      tl3.set(EL3, {autoAlpha: 0});
      tl3.to(EL3, {autoAlpha: 1});

      let tl4 = gsap.timeline({scrollTrigger: ST4});
      tl4.set(EL4, {autoAlpha: 0});
      tl4.to(EL4, {autoAlpha: 1});

	}

	// gsap.set(`#eq-qq`, {scale: 0.5, opacity: 0})
	// gsap.set(`#eq-quoteline`, {scaleX: 0, opacity: 0})

	gsap.killTweensOf(`#eq-mouse-arrow`);
	gsap.set(`#eq-mouse-arrow`, {y:-3})
	gsap.to("#eq-mouse-arrow", {y: 3, repeat:-1, yoyo: true, ease:`power2.out`, duration: 0.75});

	gsap.killTweensOf(`#eq-strokedash`);
	gsap.to(`#eq-strokedash`, {strokeDashoffset:"-=6", repeat:-1, duration: 0.2});
}