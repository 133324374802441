// extracted by mini-css-extract-plugin
export const window = "graph-module--window--23H4y";
export const h0 = "graph-module--h0--4K6z5";
export const line = "graph-module--line--ALJj_";
export const desc = "graph-module--desc--38DJZ";
export const descm = "graph-module--descm--uwZCc";
export const legend = "graph-module--legend--30VDd";
export const leg = "graph-module--leg--1vp-e";
export const source = "graph-module--source--20_VQ";
export const graph = "graph-module--graph--bF-aQ";
export const scene = "graph-module--scene--2va7R";
export const greedy = "graph-module--greedy--gDwMw";
export const yaxe = "graph-module--yaxe--3vg3q";
export const yaxevalue = "graph-module--yaxevalue--158hr";
export const xaxe = "graph-module--xaxe--3lU_N";
export const greedx = "graph-module--greedx--3ufMk";
export const xaxevalue = "graph-module--xaxevalue--2H3fc";
export const svg = "graph-module--svg--270rx";
export const points = "graph-module--points--39brA";
export const point = "graph-module--point--2fOhg";
export const pointhover = "graph-module--pointhover--1lASy";
export const pointcenter = "graph-module--pointcenter--1Tl_Y";
export const tippy = "graph-module--tippy--2YQwZ";
export const tippyyear = "graph-module--tippyyear--1Cmko";
export const tippylegend = "graph-module--tippylegend--3Nfby";
export const tippyval = "graph-module--tippyval--2zV4S";
export const eqclip = "graph-module--eqclip--13yoQ";