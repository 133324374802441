import React, { useEffect } from 'react'
import * as s from "./slide6.module.css"

import ScrollAnimation from 'react-animate-on-scroll';
import Graph from "./graph"

const Slide6 = (props) => {

	const data = props.data;
	useEffect(() => {
	}, [])

	return (
	<>
		<section className={s.slide6} id={`eq-slide6`}>
			<div className={s.box}>

  			<div className={s.window}>
  				<div className={s.h0} dangerouslySetInnerHTML={{__html: data.id + '. ' + data.h0 }} />
  				<div className={s.line} />
  				{
  					data.txt.map((d,i)=>(
  			           <ScrollAnimation key={`txt`+i} animateIn="fadeIn" animateOnce={true}>
  				           <div
  				              key={`txt`+i}
  				              className={s.txt}
  				              dangerouslySetInnerHTML={{__html: data.txt[i] }}
  				            />
  						</ScrollAnimation>
  					))
  				}
  			</div>

          {
            <div className={s.graphbox}>
            {
              props.mobile && <Graph data={data.graph} idd={`m`}/>
            }
            </div>
          }

			</div>
		</section>
	</>

)}

export default Slide6