import React, { useState, useEffect, useRef } from 'react'
import * as s from "./capacity.module.css"

import { gsap } from "gsap/dist/gsap";
import { getRandomIntInclusive } from '../utils/index.js'
import Capacitysvg from '../assets/capacity.svg';

function usePrevious(data){
  const ref = React.useRef();
  React.useEffect(()=>{
    ref.current = data
  }, [data])
  return ref.current
}

const Capacity = (props) => {

	// const data = props.data;
	const VALUES = [[2021,1200],[2025,1000],[2030,800],[2050,300]]
	const [year, setYear] = useState(0);
	const prevCount = usePrevious(year);
	const elRef = useRef();

	useEffect(() => {
		startAnimation();
	},[])

	useEffect(() => {
		let rot = elRef.current.querySelector(`#eq-rotation`)
		gsap.set(rot, {transformOrigin:"49.6% 49.8%"})
		gsap.to(rot, 0.5, {rotation:`${(45*year)}`, ease: "power2.out"});

	    let pc = prevCount ? prevCount : 0;

	    let counter0 = { var: VALUES[pc][0] };
	    gsap.to(counter0, {var: VALUES[year][0], duration: 0.5, ease: "power3.out", 
	      onUpdate: function () {
	        if(elRef.current){
	        	elRef.current.querySelector(`#eq-cap0`).textContent = `In ` + Math.ceil(counter0.var) + ` it will`;
	        }
	      }, 
	    });

	    let counter1 = { var: VALUES[pc][1] };
	    gsap.to(counter1, {var: VALUES[year][1], duration: 0.5, ease: "power3.out", 
	      onUpdate: function () {
	      	if(elRef.current){
	        	elRef.current.querySelector(`#eq-cap1`).textContent = `` + Math.ceil(counter1.var);
	        }
	      }, 
	    });
	})

	const startAnimation = ()=>{
		if(elRef.current){
			const points = gsap.utils.toArray(elRef.current.querySelectorAll(`.eq-capacitybtn`));
			let rnd = getRandomIntInclusive(0, points.length-1);
			let point = points[rnd]

			gsap.killTweensOf(points);
			gsap.set(point, {transformOrigin: `center center` })
			gsap.to(point, {scale: 1.3, duration: 1, delay: 0.5 })
			gsap.to(point, {scale: 1, duration: 1, delay: 1.5, onComplete: startAnimation})
		}
	}

	const stopAnimation = ()=>{
		let els = elRef.current.querySelectorAll(`.eq-capacitybtn`)
		gsap.killTweensOf(els);
		gsap.set(els, {scale: 1})
	}

	return (
		<section className={s.window} id={`eq-capacity`} ref={elRef}>
			<Capacitysvg />

			<svg className={s.svg} width={`620`} height={`868`} viewBox={`0 0 620 868`}>

				<g transform={`translate(-233 -201)`}>
					<g transform={`translate(-380 50)`}>
						<text transform={"translate(775 324)"} fill={"#243746"} fontSize={"50"} fontFamily={"FinancierDisplay-Regular, Financier Display"}>
							<tspan x="26.125" y="40" id={`eq-cap0`}></tspan>
							<tspan x="82.55" y="95">take an </tspan>
							<tspan x="5.075" y="150">estimated </tspan>
							<tspan y="150" fontFamily="FinancierDisplay-Bold, Financier Display" fontWeight="700" id={`eq-cap1`}></tspan>
							<tspan y="150"> </tspan><tspan x="48.5" y="205">turbines to </tspan>
							<tspan x="34.025" y="260">make </tspan>
							<tspan y="260" fontFamily="FinancierDisplay-Bold, Financier Display" fontWeight="700">10GW</tspan>
						</text>
					</g>
				</g>

				<g transform="translate(-233 -201)">
				    <g transform="translate(-455 1139)">
				      
				      <g className={s.btn + ` eq-capacitybtn`} data-id={0} onMouseEnter={() => { stopAnimation();setYear(0)} }>
					      <g transform="matrix(0.454, -0.891, 0.891, 0.454, 1057.774, -824.737)">
					          <text fill="#283745" fontSize={(year===0)?`30`:`20`} fontFamily={(year===0)?`Metric-Bold`:`Metric-Medium`} fontWeight={(year===0)?`700`:`500`}>
					          	<tspan x="0" y="28">2021</tspan>
					          </text>
					      </g>
				      </g>

				      <g className={s.btn + ` eq-capacitybtn`} data-id={1} onMouseEnter={() => { stopAnimation();setYear(1)} }>
					      <g id="Сгруппировать_6497" data-name="Сгруппировать 6497" transform="translate(1178.094 -714.677) rotate(-21)">
					          <text fill="#253746" fontSize={(year===1)?`30`:`20`} fontFamily={(year===1)?`Metric-Bold`:`Metric-Medium`} fontWeight={(year===1)?`700`:`500`}>
					          	<tspan x="0" y="18">2025</tspan>
					          </text>
					      </g>
				      </g>

					  <g className={s.btn + ` eq-capacitybtn`} data-id={2} onMouseEnter={() => { stopAnimation();setYear(2)} }>
					      <g transform="translate(1186.695 -564.729) rotate(21)">
					          <text transform="translate(1.575 6.893)" fill="#253746" fontSize={(year===2)?`30`:`20`} fontFamily={(year===2)?`Metric-Bold`:`Metric-Medium`} fontWeight={(year===2)?`700`:`500`}>
					          	<tspan x="0" y="18">2030</tspan>
					          </text>
					      </g>
				      </g>

					  <g className={s.btn + ` eq-capacitybtn`} data-id={3} onMouseEnter={() => { stopAnimation();setYear(3)} }>
					      <g transform="translate(1186.695 -564.729) rotate(21)">
					          <text transform="translate(-52.815 150.593) rotate(45)" fill="#253746" fontSize={(year===3)?`30`:`20`} fontFamily={(year===3)?`Metric-Bold`:`Metric-Medium`} fontWeight={(year===3)?`700`:`500`}>
					          	<tspan x="0" y="18">2050</tspan>
					          </text>
					      </g>
				      </g>

				    </g>
				</g>

			</svg>
		</section>
)}

export default Capacity