import React, { useEffect } from 'react'
import * as s from "./slide1.module.css"

const Slide1 = (props) => {

	const data = props.data;
	useEffect(() => {
	}, [])

	return (
		<>
		
      <section className={s.slide1a} id={`eq-st0`}>
      	<div className={s.window}>
      		<div className={s.h0} dangerouslySetInnerHTML={{__html: data.id + '. ' + data.h0 }} />
      		<div className={s.line} />
      		{
      			data.txt.map((d,i)=>(
      	           <div
      	              key={`txt`+i}
      	              className={s.txt}
                          data-id={i}
      	              dangerouslySetInnerHTML={{__html: data.txt[i] }}
      	            />
      			))
      		}
      	</div>
      </section>

      <section className={s.slide1b} id={`eq-st1`}>
      	<div className={s.window}>
      		<div className={s.facth0} dangerouslySetInnerHTML={{__html: data.facts.h0 }} />
      		<div className={s.line} />
      		{
      			data.facts.txt.map((d,i)=>(
      	           <div
      	              key={`txt`+i}
      	              data-id={i}
      	              className={s.factstxt}
      	              dangerouslySetInnerHTML={{__html: data.facts.txt[i] }}
      	            />
      			))
      		}
      	</div>
      </section>
    
		</>
)}

export default Slide1
