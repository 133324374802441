import React, { useEffect, useRef } from 'react'
import * as s from "./graph.module.css"
import Tippy from '@tippyjs/react/headless';
import { gsap } from "gsap/dist/gsap";
import { getRandomIntInclusive } from '../utils/index.js'

const tip = `.` + s.tippy


const Graph = (props) => {

	const data = props.data;
	const dt = data.data;
	const xaxis = [2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
	const yaxis = [175, 150, 125, 100, 75, 50, 25, 0]
	const elRef = useRef();

	useEffect(() => {
		startAnimation();
	}, [])

	function onMount() {
	    gsap.killTweensOf(tip);
	    gsap.set(tip, {autoAlpha: 0, transformOrigin:"50% 50%"});
	    gsap.to(tip, {autoAlpha: 1, duration: 0.5, ease: `power2.out`});
	}
	function onHide({ unmount }) {
	    // gsap.killTweensOf(tip);
	    // gsap.set(tip, {autoAlpha: 0, transformOrigin:"50% 50%"});
	}

	const startAnimation = ()=>{
		if(elRef.current){
			const points = gsap.utils.toArray(elRef.current.querySelectorAll(`.eq-graphpoint`));
			let rnd = getRandomIntInclusive(0, points.length-1);
			let point = points[rnd]
			gsap.killTweensOf([`.eq-graphpoint`]);
			gsap.set(point, {backgroundColor: `rgba(255,255,255,1)`, scale: 0.1 })
			gsap.to(point, {backgroundColor: `rgba(255,255,255,0.0)`, scale: 1, duration: 1, delay: 1, onComplete: startAnimation})
		}
	}

	const stopAnimation = ()=>{
		let els = elRef.current.querySelectorAll(`.eq-graphpoint`)
		gsap.killTweensOf(els);
		gsap.set(els, {backgroundColor: `rgba(255,255,255,0.0)` })
	}

	return (
		<section className={s.window} ref={elRef}>
			<div className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
			<div className={s.line} />
			<div className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
			<div className={s.descm} dangerouslySetInnerHTML={{__html: data.descm }} />
			<div className={s.legend}>
			{
				data.legend.map((d,i)=>(
					<div 
						className={s.leg}
						key={i}
						data-id={i}
						dangerouslySetInnerHTML={{__html: d }}
					/>
				))
			}
			</div>
			<div className={s.graph}>
				<div className={s.scene}>
					<div className={s.greedy}>
						{
							yaxis.map((d,i) => (
					            <div
					              key={`y`+i}
					              className={s.yaxe}
					            >
					            	<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: `£` + d }} />
					            </div>
				            ))
						}
					</div>
					<div className={s.greedx}>
						{
							xaxis.map((d,i) => (
					            <div
					              key={`x`+i}
					              className={s.xaxe}
					            >
					            	<div className={s.xaxevalue} dangerouslySetInnerHTML={{__html: d }} />
					            </div>
				            ))
						}
					</div>
					<svg className={s.svg} xmlns="http://www.w3.org/2000/svg" width="100%" height="230" viewBox="0 0 462 230" preserveAspectRatio="none">
						<defs>
							<clipPath id={`eqclip` + props.idd}>
								<rect x="0" y="0" width="100%" height="230" id="eqgclip" className={s.eqclip} fill="#fff"/>
							</clipPath>
						</defs>

						<g clipPath={`url(#eqclip${props.idd})`}>
							<line x1="0" y1={230-dt[0][0]/175*230} x2="66" y2={230-dt[0][1]/175*230} stroke="#2C6081" strokeWidth="2" />
							<line x1="66" y1={230-dt[0][1]/175*230} x2="132" y2={230-dt[0][2]/175*230} stroke="#2C6081" strokeWidth="2" />
							<line x1="132" y1={230-dt[0][2]/175*230} x2="198" y2={230-dt[0][3]/175*230} stroke="#2C6081" strokeWidth="2" />
							<line x1="198" y1={230-dt[0][3]/175*230} x2="264" y2={230-dt[0][4]/175*230} stroke="#2C6081" strokeWidth="2" />
							<line x1="264" y1={230-dt[0][4]/175*230} x2="330" y2={230-dt[0][5]/175*230} stroke="#2C6081" strokeWidth="2" />
							<line x1="330" y1={230-dt[0][5]/175*230} x2="396" y2={230-dt[0][6]/175*230} stroke="#2C6081" strokeWidth="2" />
							<line x1="396" y1={230-dt[0][6]/175*230} x2="462" y2={230-dt[0][7]/175*230} stroke="#2C6081" strokeWidth="2" />
						</g>
						<g clipPath={`url(#eqclip${props.idd})`}>
							<line x1="0" y1={230-dt[1][0]/175*230} x2="66" y2={230-dt[1][1]/175*230} stroke="#2E6E77" strokeWidth="2"  strokeDasharray="4"/>
							<line x1="66" y1={230-dt[1][1]/175*230} x2="132" y2={230-dt[1][2]/175*230} stroke="#2E6E77" strokeWidth="2"  strokeDasharray="4"/>
							<line x1="132" y1={230-dt[1][2]/175*230} x2="198" y2={230-dt[1][3]/175*230} stroke="#2E6E77" strokeWidth="2"  strokeDasharray="4"/>
							<line x1="198" y1={230-dt[1][3]/175*230} x2="264" y2={230-dt[1][4]/175*230} stroke="#2E6E77" strokeWidth="2"  strokeDasharray="4"/>
							<line x1="264" y1={230-dt[1][4]/175*230} x2="330" y2={230-dt[1][5]/175*230} stroke="#2E6E77" strokeWidth="2"  strokeDasharray="4"/>
							<line x1="330" y1={230-dt[1][5]/175*230} x2="396" y2={230-dt[1][6]/175*230} stroke="#2E6E77" strokeWidth="2"  strokeDasharray="4"/>
							<line x1="396" y1={230-dt[1][6]/175*230} x2="462" y2={230-dt[1][7]/175*230} stroke="#2E6E77" strokeWidth="2"  strokeDasharray="4"/>
						</g>
						<g clipPath={`url(#eqclip${props.idd})`}>
							<line x1="0" y1={230-dt[2][0]/175*230} x2="66" y2={230-dt[2][1]/175*230} stroke="#283745" strokeWidth="2" />
							<line x1="66" y1={230-dt[2][1]/175*230} x2="132" y2={230-dt[2][2]/175*230} stroke="#283745" strokeWidth="2" />
							<line x1="132" y1={230-dt[2][2]/175*230} x2="198" y2={230-dt[2][3]/175*230} stroke="#283745" strokeWidth="2" />
							<line x1="198" y1={230-dt[2][3]/175*230} x2="264" y2={230-dt[2][4]/175*230} stroke="#283745" strokeWidth="2" />
							<line x1="264" y1={230-dt[2][4]/175*230} x2="330" y2={230-dt[2][5]/175*230} stroke="#283745" strokeWidth="2" />
							<line x1="330" y1={230-dt[2][5]/175*230} x2="396" y2={230-dt[2][6]/175*230} stroke="#283745" strokeWidth="2" />
							<line x1="396" y1={230-dt[2][6]/175*230} x2="462" y2={230-dt[2][7]/175*230} stroke="#283745" strokeWidth="2" />
						</g>
						<g clipPath={`url(#eqclip${props.idd})`}>
							<line x1="198" y1={230-dt[3][3]/175*230} x2="264" y2={230-dt[3][4]/175*230} stroke="#E9B5BF" strokeWidth="2" />
							<line x1="264" y1={230-dt[3][4]/175*230} x2="330" y2={230-dt[3][5]/175*230} stroke="#E9B5BF" strokeWidth="2" />
							<line x1="330" y1={230-dt[3][5]/175*230} x2="396" y2={230-dt[3][6]/175*230} stroke="#E9B5BF" strokeWidth="2" />
							<line x1="396" y1={230-dt[3][6]/175*230} x2="462" y2={230-dt[3][7]/175*230} stroke="#E9B5BF" strokeWidth="2" />
						</g>
						<g clipPath={`url(#eqclip${props.idd})`}>
							<line x1="0" y1={230-dt[4][0]/175*230} x2="66" y2={230-dt[4][1]/175*230} stroke="#EB384A" strokeWidth="2" />
							<line x1="66" y1={230-dt[4][1]/175*230} x2="132" y2={230-dt[4][2]/175*230} stroke="#EB384A" strokeWidth="2" />
							<line x1="132" y1={230-dt[4][2]/175*230} x2="198" y2={230-dt[4][3]/175*230} stroke="#EB384A" strokeWidth="2" />
							<line x1="198" y1={230-dt[4][3]/175*230} x2="264" y2={230-dt[4][4]/175*230} stroke="#EB384A" strokeWidth="2" />
							<line x1="264" y1={230-dt[4][4]/175*230} x2="330" y2={230-dt[4][5]/175*230} stroke="#EB384A" strokeWidth="2" />
							<line x1="330" y1={230-dt[4][5]/175*230} x2="396" y2={230-dt[4][6]/175*230} stroke="#EB384A" strokeWidth="2" />
							<line x1="396" y1={230-dt[4][6]/175*230} x2="462" y2={230-dt[4][7]/175*230} stroke="#EB384A" strokeWidth="2" />
						</g>
						<g clipPath={`url(#eqclip${props.idd})`}>
							<line x1="132" y1={230-dt[5][2]/175*230} x2="198" y2={230-dt[5][3]/175*230} stroke="#EB384A" strokeWidth="2" strokeDasharray="4"/>
							<line x1="198" y1={230-dt[5][3]/175*230} x2="264" y2={230-dt[5][4]/175*230} stroke="#EB384A" strokeWidth="2" strokeDasharray="4"/>
							<line x1="264" y1={230-dt[5][4]/175*230} x2="330" y2={230-dt[5][5]/175*230} stroke="#EB384A" strokeWidth="2" strokeDasharray="4"/>
							<line x1="330" y1={230-dt[5][5]/175*230} x2="396" y2={230-dt[5][6]/175*230} stroke="#EB384A" strokeWidth="2" strokeDasharray="4"/>
							<line x1="396" y1={230-dt[5][6]/175*230} x2="462" y2={230-dt[5][7]/175*230} stroke="#EB384A" strokeWidth="2" strokeDasharray="4"/>
						</g>
					</svg>
					<div className={s.points} id={`eq-graphpoints`}>
					{
						dt.map((d,i)=>(
							d.map((q,j)=>(
	                            <Tippy
	                              key={i+""+j} 
	                              appendTo={`parent`}
								onMount={onMount}
								onHide={onHide}
	                              render={attrs => (
	                                <div 
	                                  className={s.tippy}
	                                  data-id={i} 
	                                  {...attrs}
	                                >
	                                  <div className={s.tippyyear} dangerouslySetInnerHTML={{__html: xaxis[j] }}/>
	                                  <div className={s.tippylegend} dangerouslySetInnerHTML={{__html: data.legend[i] }}/>
	                                  <div className={s.tippyval} data-id={i} dangerouslySetInnerHTML={{__html: `£` + q + `/MWH` }}/>
	                                </div>
	                              )}
	                            >
								<div 
									onMouseEnter={()=>{ stopAnimation(); }}
									className={s.point}
									data-id={i}
				                	role = "button"
					                tabIndex="0"
					                onKeyDown={()=>{ stopAnimation(); }}
									style={{
										"left": `${14.29*j}%`,
										"top": `${100-dt[i][j]/175*100}%`,
										"display": `${(dt[i][j]!==0)?"block":"none"}`
									}}
								>
									<div className={s.pointcenter} data-id={i}>
									</div>

									<div className={s.pointhover + ` eq-graphpoint`} data-id={i}>
									</div>
								</div>
								</Tippy>
							))
						))
					}
					</div>
				</div>
			</div>
			<div className={s.source} dangerouslySetInnerHTML={{__html: data.source }} />
		</section>
)}

export default Graph